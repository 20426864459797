import Vue from 'vue'

export default {
  // Document settings
  SET_DOCUMENT_SETTINGS: (state, items) => state.documentSettings = items,
  ADD_DOCUMENT_SETTINGS: (state, item) => state.documentSettings.push(item),
  EDIT_DOCUMENT_SETTINGS(state, item) {
    let index = state.documentSettings.findIndex(element => element.id === item.id)
    Vue.set(state.documentSettings, index, item)
  },
  // Patient reception schedule settings
  SET_PATIENT_RECEPTION_SCHEDULE_SETTINGS: (state, items) => state.patientReceptionScheduleSettings = items,
  ADD_PATIENT_RECEPTION_SCHEDULE_SETTINGS: (state, item) => state.patientReceptionScheduleSettings.push(item),
  EDIT_PATIENT_RECEPTION_SCHEDULE_SETTINGS(state, item) {
    let index = state.patientReceptionScheduleSettings.findIndex(element => element.id === item.id)
    Vue.set(state.patientReceptionScheduleSettings, index, item)
  },
  // Cash register settings
  SET_CASH_REGISTER_SETTINGS: (state, items) => state.cashRegisterSettings = items,
  ADD_CASH_REGISTER_SETTINGS: (state, item) => state.cashRegisterSettings.push(item),
  EDIT_CASH_REGISTER_SETTINGS(state, item) {
    let index = state.cashRegisterSettings.findIndex(element => element.id === item.id)
    Vue.set(state.cashRegisterSettings, index, item)
  },
  // Price settings
  SET_PRICE_SETTINGS: (state, items) => state.priceSettings = items,
  ADD_PRICE_SETTINGS: (state, item) => state.priceSettings.push(item),
  EDIT_PRICE_SETTINGS(state, item) {
    let index = state.priceSettings.findIndex(element => element.id === item.id)
    Vue.set(state.priceSettings, index, item)
  },
  // Treatment diary settings
  SET_TREATMENT_DIARY_SETTINGS: (state, items) => state.treatmentDiarySettings = items,
  ADD_TREATMENT_DIARY_SETTINGS: (state, item) => state.treatmentDiarySettings.push(item),
  EDIT_TREATMENT_DIARY_SETTINGS(state, item) {
    let index = state.treatmentDiarySettings.findIndex(element => element.id === item.id)
    Vue.set(state.treatmentDiarySettings, index, item)
  },
  // Patient card settings
  SET_PATIENT_CARD_SETTINGS: (state, items) => state.patientCardSettings = items,
  ADD_PATIENT_CARD_SETTINGS: (state, item) => state.patientCardSettings.push(item),
  EDIT_PATIENT_CARD_SETTINGS(state, item) {
    let index = state.patientCardSettings.findIndex(element => element.id === item.id)
    Vue.set(state.patientCardSettings, index, item)
  },
  // Employee settings
  SET_EMPLOYEE_SETTINGS: (state, items) => state.employeeSettings = items,
  ADD_EMPLOYEE_SETTINGS: (state, item) => state.employeeSettings.push(item),
  EDIT_EMPLOYEE_SETTINGS(state, item) {
    let index = state.employeeSettings.findIndex(element => element.id === item.id)
    Vue.set(state.employeeSettings, index, item)
  },
  // Dental service settings
  SET_DENTAL_SERVICE_SETTINGS: (state, items) => state.dentalServiceSettings = items,
  ADD_DENTAL_SERVICE_SETTINGS: (state, item) => state.dentalServiceSettings.push(item),
  EDIT_DENTAL_SERVICE_SETTINGS(state, item) {
    let index = state.dentalServiceSettings.findIndex(element => element.id === item.id)
    Vue.set(state.dentalServiceSettings, index, item)
  },
  // Treatment presentation settings
  SET_TREATMENT_PRESENTATION_SETTINGS: (state, items) => state.treatmentPresentationSettings = items,
  ADD_TREATMENT_PRESENTATION_SETTINGS: (state, item) => state.treatmentPresentationSettings.push(item),
  EDIT_TREATMENT_PRESENTATION_SETTINGS(state, item) {
    let index = state.treatmentPresentationSettings.findIndex(e => e.id === item.id)
    Vue.set(state.treatmentPresentationSettings, index, item)
  },
}
