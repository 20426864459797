import { getUserData } from '@/auth/utils'

export const advisoryOpinionCompactViewEnabled = () => {
  const settings = getUserDocumentSettings()
  return !!(settings && settings.advisory_opinion_compact_view)
}

export const advisoryOpinionSaveLineBreaks = () => {
  const settings = getUserDocumentSettings()
  return !!(settings && settings.advisory_opinion_save_line_breaks)
}

export const dentalCardUseConsentProcessingPersonalData = () => {
  const settings = getUserDocumentSettings()
  return settings && settings.hasOwnProperty('dental_card_use_consent_processing_personal_data')
    ? settings.dental_card_use_consent_processing_personal_data : true
}

export const documentLogoRightPosition = () => {
  const settings = getUserDocumentSettings()
  return !!(settings && settings.logo_right_position)
}

export const documentIntegratorInfoWithLogo = () => {
  const settings = getUserDocumentSettings()
  return !!(settings && settings.integrator_info_with_logo)
}

export const actContractNumberHidden = () => {
  const settings = getUserDocumentSettings()
  return !!(settings && settings.act_contract_number_hidden)
}

export const actFillSignerDoctorName = () => {
  const settings = getUserDocumentSettings()
  return !!(settings && settings.act_fill_signer_doctor_name)
}

export const actPatientLabel = () => {
  const settings = getUserDocumentSettings()
  return settings && settings.act_patient_label ? settings.act_patient_label : 'Пациент'
}

export const complexActHideColumnPrice = () => {
  const settings = getUserDocumentSettings()
  return !!(settings && settings.complex_act_hide_column_price)
}

export const purchaseOrderExecutorLabel = () => {
  const settings = getUserDocumentSettings()
  return settings && settings.purchase_order_executor_label ? settings.purchase_order_executor_label : 'Исполнитель'
}

export const purchaseOrderAdministratorHidden = () => {
  const settings = getUserDocumentSettings()
  return !!(settings && settings.purchase_order_administrator_hidden)
}

export const purchaseOrderFillExecutorDoctorName = () => {
  const settings = getUserDocumentSettings()
  return !!(settings && settings.purchase_order_fill_executor_doctor_name)
}

export const purchaseOrderShowDiagnosis = () => {
  const settings = getUserDocumentSettings()
  return !!(settings && settings.purchase_order_show_diagnosis)
}

const getUserDocumentSettings = () => {
  const userData = getUserData()
  return userData && userData.document_settings ? userData.document_settings : null
}
