import Vue from 'vue'

export default {
  // Role types
  SET_ROLE_TYPES(state, roleTypes) {
    state.roleTypes = roleTypes
  },
  // Doctors
  SET_DOCTORS(state, doctors) {
    state.doctors = doctors
  },
  CLEAR_DOCTORS(state) {
    state.doctors = []
  },
  // Admins
  SET_ADMINS(state, items) {
    state.admins = items
  },
  // Lab Technicians
  SET_LAB_TECHNICIANS(state, items) {
    state.lab_technicians = items
  },
  // Nurses
  SET_NURSES(state, items) {
    state.nurses = items
  },
  // Medical staff
  SET_MEDICAL_STAFF(state, items) {
    state.medicalStaff = items
  },
  // Operators
  SET_OPERATORS(state, items) {
    state.operators = items
  },
  // All users
  SET_ALL_USERS(state, items) {
    state.allUsers = items
  },
  // Lines of work
  SET_EMPLOYEE_LINES_OF_WORK(state, linesOfWork) {
    state.linesOfWork = linesOfWork
  },
  ADD_LINE_OF_WORK(state, lineOfWork) {
    state.linesOfWork.push(lineOfWork)
  },
  EDIT_LINE_OF_WORK(state, lineOfWork) {
    const index = state.linesOfWork.findIndex(pd => pd.id === lineOfWork.id)
    state.linesOfWork[index].name = lineOfWork.name
    state.linesOfWork[index].type = lineOfWork.type
  },
  REMOVE_LINE_OF_WORK(state, itemId) {
    const index = state.linesOfWork.findIndex(lineOfWork => lineOfWork.id === itemId)
    state.linesOfWork.splice(index, 1)
  },
  // Lines of work types
  SET_EMPLOYEE_LINES_OF_WORK_TYPES(state, linesOfWorkTypes) {
    state.linesOfWorkTypes = linesOfWorkTypes
  },
  // Direction
  SET_DIRECTIONS_PER_PAGE: (state, items) => state.directionsPerPage = items,
  SET_DIRECTIONS_PER_PAGE_COUNT: (state, count) => state.directionsPerPageCount = count,
  SET_DIRECTIONS: (state, items) => state.directions = items,
  ADD_DIRECTION(state, direction) {
    state.directionsPerPage.push(direction)
    state.directionsPerPageCount += 1
  },
  EDIT_DIRECTION(state, item) {
    const index = state.directionsPerPage.findIndex(d => d.id === item.id)
    Vue.set(state.directionsPerPage, index, item)
  },
  REMOVE_DIRECTION(state, itemId) {
    const index = state.directionsPerPage.findIndex(direction => direction.id === itemId)
    state.directionsPerPage.splice(index, 1)
    state.directionsPerPageCount -= 1
  },
  // Direction settings
  SET_DIRECTION_SETTINGS: (state, settings) => state.directionSettings = settings,
  // Department
  SET_DEPARTMENTS_PER_PAGE: (state, items) => state.departmentsPerPage = items,
  SET_DEPARTMENTS_PER_PAGE_COUNT: (state, count) => state.departmentsPerPageCount = count,
  SET_DEPARTMENTS: (state, items) => state.departments = items,
  ADD_DEPARTMENT(state, direction) {
    state.departmentsPerPage.push(direction)
    state.departmentsPerPageCount += 1
  },
  EDIT_DEPARTMENT(state, item) {
    const index = state.departmentsPerPage.findIndex(d => d.id === item.id)
    Vue.set(state.departmentsPerPage, index, item)
  },
  REMOVE_DEPARTMENT(state, itemId) {
    const index = state.departmentsPerPage.findIndex(direction => direction.id === itemId)
    state.departmentsPerPage.splice(index, 1)
    state.departmentsPerPageCount -= 1
  },
  // Integrator branches
  SET_INTEGRATOR_BRANCHES: (state, items) => state.integratorBranches = items,
  SET_USER_INTEGRATOR_BRANCHES: (state, items) => state.userIntegratorBranches = items,
  // User office phone numbers
  SET_USER_OFFICE_PHONE_NUMBERS_PER_PAGE: (state, items) => state.userOfficePhoneNumbersPerPage = items,
  SET_USER_OFFICE_PHONE_NUMBERS_PER_PAGE_COUNT: (state, count) => state.userOfficePhoneNumbersPerPageCount = count,
  USER_OFFICE_PHONE_NUMBER_ADD(state, item) {
    state.userOfficePhoneNumbersPerPage.push(item)
    state.userOfficePhoneNumbersPerPageCount += 1
  },
  USER_OFFICE_PHONE_NUMBER_EDIT(state, item) {
    const index = state.userOfficePhoneNumbersPerPage.findIndex(element => element.id === item.id)
    Vue.set(state.userOfficePhoneNumbersPerPage, index, item)
  },
  USER_OFFICE_PHONE_NUMBER_EDIT_IS_MAIN(state, item) {
    state.userOfficePhoneNumbersPerPage.map(phone => {
      phone.is_main = phone.id === item.id ? item.is_main : false
      return phone
    })
  },
  USER_OFFICE_PHONE_NUMBER_DELETE(state, id) {
    const index = state.userOfficePhoneNumbersPerPage.findIndex(item => item.id === id)
    state.userOfficePhoneNumbersPerPage.splice(index, 1)
    state.userOfficePhoneNumbersPerPageCount -= 1
  },
}
