import {
  entityBite, entityContacts, entityDentalservicesreference, entityDiagnosis, entityDiscountcards, entityMaterial,
  entityPaymentmethod, entityGroup, entityTreatmentplans, entityUser, entityIntegratorRoot, contactsfrom,
  entityMaterialunittype, entityServiceTime, entityPatientType, entityDictionaryPatientTemplate, entityPaymentDirection,
  entityAdvisoryOpinion, entityEmployeeLineOfWork, entityDirection, entityImportExport, entityInsuranceCompany,
  entityLegalPerson, entityPatientAppealResult, entityDictionaryNotificationConditionCategory, entityEmailSettings,
  entityDocumentSettings, entityPatientReceptionScheduleSettings, entityCashRegisterSettings,
  entityDictionaryTreatmentFeature, entityDictionaryNds, entityPricingTypeValue, entityPriceSettings,
  entityPatientAppointmentStatus, entityTreatmentDiarySettings, entityEmployeeSettings, entityDepartment,
  entityMedicalServiceType, entitySpecialistType, entityMaterialPriceList, entityDentalServiceSettings,
  entityAdvertisingPlatformUtmSource, entityPatientCardSettings, entityAdvertisingPlatformUtmMedium,
  entityImplantSystem, entityImplantWorkType, entityMaterialType, entityWorkType, entityIntegratorBranch,
  entityColorPalette, entityThirdPartyOrganization, entityRecommendedVisitType, entityDictionaryCounterparty,
  entityToothState, entityTreatmentPresentationSettings,
} from '@/libs/acl/entities'

export default [
  {
    header: 'Dictionary',
    icon: 'StarIcon',
    action: 'read',
    route: 'dictionary',
    children: [
      {
        title: 'Managing',
        action: 'read',
        route: 'managing',
        children: [
          {
            route: 'dictionary-integrator',
            title: 'Clinics',
            resource: entityIntegratorRoot,
            action: 'read',
          },
          {
            route: 'dictionary-branch',
            title: 'Branches',
            resource: entityIntegratorBranch,
            action: 'read',
          },
          {
            route: 'physician-protocol-constructor',
            title: 'Physician protocol constructor',
            resource: entityDictionaryPatientTemplate,
            action: 'read',
          },
          {
            route: 'dictionary-insurance-company',
            title: 'Insurance companies',
            resource: entityInsuranceCompany,
            action: 'read',
          },
          {
            route: 'dictionary-legal-person',
            title: 'Legal persons',
            resource: entityLegalPerson,
            action: 'read',
          },
          {
            route: 'dictionary-third-party-organization',
            title: 'Third-party organizations',
            resource: entityThirdPartyOrganization,
            action: 'read',
          },
          {
            route: 'dictionary-counterparty',
            title: 'Counterparties',
            resource: entityDictionaryCounterparty,
            action: 'read',
          },
          {
            route: 'dictionary-roles',
            title: 'Roles',
            resource: entityGroup,
            action: 'read',
          },
          {
            route: 'dictionary-employee',
            title: 'Employee',
            resource: entityUser,
            action: 'read',
          },
          {
            route: 'dictionary-employee-line-of-work',
            title: 'Employees line of work',
            resource: entityEmployeeLineOfWork,
            action: 'read',
          },
          {
            route: 'dictionary-department',
            title: 'Departments',
            resource: entityDepartment,
            action: 'read',
          },
          {
            route: 'dictionary-direction',
            title: 'Directions',
            resource: entityDirection,
            action: 'read',
          },
          {
            route: 'dictionary-specialist-type',
            title: 'Types of specialists',
            resource: entitySpecialistType,
            action: 'read',
          },
          {
            route: 'dictionary-pricing-type-value',
            title: 'Overhead costs',
            resource: entityPricingTypeValue,
            action: 'read',
          },
          {
            route: 'import-export',
            title: 'Import/Export',
            resource: entityImportExport,
            action: 'read',
          },
          {
            route: 'dictionary-notifications',
            title: 'Sending out notifications',
            resource: entityDictionaryNotificationConditionCategory,
            action: 'read',
          },
          {
            route: 'dictionary-user-activity-log',
            title: 'User activity log',
            resource: entityUser,
            action: 'read',
          },
          {
            title: 'Settings',
            icon: 'SettingsIcon',
            resource: entityGroup,
            action: 'read',
            route: 'settings',
            children: [
              {
                title: 'Email settings',
                route: 'dictionary-email-settings',
                resource: entityEmailSettings,
                action: 'read',
              },
              {
                title: 'Document settings',
                route: 'dictionary-document-settings',
                resource: entityDocumentSettings,
                action: 'read',
              },
              {
                title: 'Employee settings',
                route: 'dictionary-employee-settings',
                resource: entityEmployeeSettings,
                action: 'read',
              },
              {
                title: 'Patient reception schedule settings',
                route: 'dictionary-patient-reception-schedule-settings',
                resource: entityPatientReceptionScheduleSettings,
                action: 'read',
              },
              {
                title: 'Cash register settings',
                route: 'dictionary-cash-register-settings',
                resource: entityCashRegisterSettings,
                action: 'read',
              },
              {
                title: 'Modules settings',
                route: 'dictionary-modules-settings',
                resource: entityIntegratorRoot,
                action: 'read',
              },
              {
                title: 'Price settings',
                route: 'dictionary-price-settings',
                resource: entityPriceSettings,
                action: 'read',
              },
              {
                title: 'Treatment diary settings',
                route: 'dictionary-treatment-diary-settings',
                resource: entityTreatmentDiarySettings,
                action: 'read',
              },
              {
                title: 'Dental services settings',
                route: 'dictionary-dental-service-settings',
                resource: entityDentalServiceSettings,
                action: 'read',
              },
              {
                title: 'Patient card settings',
                route: 'dictionary-patient-card-settings',
                resource: entityPatientCardSettings,
                action: 'read',
              },
              {
                title: 'Treatment presentation settings',
                route: 'dictionary-treatment-presentation-settings',
                resource: entityTreatmentPresentationSettings,
                action: 'read',
              },
            ]
          },
        ]
      },
      {
        title: 'Patients',
        action: 'read',
        route: 'patients-dict',
        children: [
          {
            route: 'dictionary-patient-template',
            title: 'Patient card templates',
            resource: entityDictionaryPatientTemplate,
            action: 'read',
          },
          {
            route: 'patient-type',
            title: 'Patient types',
            resource: entityPatientType,
            action: 'read',
          },
          {
            route: 'dictionary-patient-appeal-result',
            title: 'Patient appeal results',
            resource: entityPatientAppealResult,
            action: 'read',
          },
        ]
      },
      {
        title: 'Revenue',
        action: 'read',
        route: 'revenue-dict',
        children: [
          {
            route: 'dictionary-payment-direction',
            title: 'PaymentDirection',
            resource: entityPaymentDirection,
            action: 'read',
          },
          {
            route: 'dictionary-payment-method',
            title: 'PaymentMethod',
            resource: entityPaymentmethod,
            action: 'read',
          },
          {
            route: 'dictionary-discount-cards',
            title: 'DiscountCards',
            resource: entityDiscountcards,
            action: 'read',
          },
        ]
      },
      {
        title: 'Schedule',
        action: 'read',
        route: 'schedule-dict',
        children: [
          {
            route: 'dictionary-type-contact',
            title: 'TypeContact',
            resource: entityContacts,
            action: 'read',
          },
          {
            route: 'contacts-from',
            title: 'How contacted?',
            resource: contactsfrom,
            action: 'read',
          },
          {
            route: 'service-time',
            title: 'Service time',
            resource: entityServiceTime,
            action: 'read',
          },
          {
            route: 'dictionary-patient-appointment-status',
            title: 'Patient appointment statuses',
            resource: entityPatientAppointmentStatus,
            action: 'read',
          },
        ]
      },
      {
        title: 'Treatment',
        action: 'read',
        route: 'treatment',
        children: [
          {
            route: 'material-price-list',
            title: 'Material price list',
            resource: entityMaterialPriceList,
            action: 'read',
          },
          {
            route: 'dictionary-dental-services',
            title: 'DentalServices',
            resource: entityDentalservicesreference,
            action: 'read',
          },
          {
            route: 'dictionary-medical-services-type',
            title: 'Medical services type',
            resource: entityMedicalServiceType,
            action: 'read',
          },
          {
            route: 'dictionary-treatment-plans',
            title: 'Complexes',
            resource: entityTreatmentplans,
            action: 'read',
          },
          {
            route: 'dictionary-diagnosis',
            title: 'Diagnosis',
            resource: entityDiagnosis,
            action: 'read',
          },
          {
            route: 'dictionary-treatment-features',
            title: 'Treatment features',
            resource: entityDictionaryTreatmentFeature,
            action: 'read',
          },
          {
            title: 'Treatment presentation',
            action: 'read',
            route: 'dictionary-treatment-presentation',
            children: [
              {
                title: 'Teeth states',
                action: 'read',
                route: 'dictionary-tooth-state',
                resource: entityToothState
              }
            ]
          },
          {
            route: 'dictionary-bites',
            title: 'Bites',
            resource: entityBite,
            action: 'read',
          },
          {
            route: 'dictionary-materials',
            title: 'Materials',
            resource: entityMaterial,
            action: 'read',
          },
          {
            route: 'dictionary-units',
            title: 'Units',
            resource: entityMaterialunittype,
            action: 'read',
          },
          {
            route: 'dictionary-nds',
            title: 'Nds',
            resource: entityDictionaryNds,
            action: 'read',
          },
          {
            route: 'dictionary-advisory-opinion',
            title: 'Advisory opinion',
            resource: entityAdvisoryOpinion,
            action: 'read',
          },
          {
            route: 'dictionary-recommended-visit-type',
            title: 'Recommended visit types',
            resource: entityRecommendedVisitType,
            action: 'read',
          },
        ]
      },
      {
        title: 'Leads',
        action: 'read',
        route: 'dictionary-leads',
        children: [
          {
            title: 'UTM-tags',
            action: 'read',
            route: 'dictionary-utm-tags',
            children: [
              {
                route: 'dictionary-utm-source',
                title: 'UTM-sources',
                resource: entityAdvertisingPlatformUtmSource,
                action: 'read',
              },
              {
                route: 'dictionary-utm-medium',
                title: 'UTM-mediums',
                resource: entityAdvertisingPlatformUtmMedium,
                action: 'read',
              },
            ]
          },
        ]
      },
      {
        title: 'Dental laboratory',
        action: 'read',
        route: 'dictionary-dental-laboratory',
        children: [
          {
            title: 'Work types',
            action: 'read',
            route: 'dictionary-work-type',
            resource: entityWorkType
          },
          {
            title: 'Material types',
            action: 'read',
            route: 'dictionary-material-type',
            resource: entityMaterialType
          },
          {
            title: 'Work type on implant',
            action: 'read',
            route: 'dictionary-implant-work-type',
            resource: entityImplantWorkType
          },
          {
            title: 'Implant system',
            action: 'read',
            route: 'dictionary-implant-system',
            resource: entityImplantSystem
          },
          {
            title: 'Color palette',
            action: 'read',
            route: 'dictionary-color-palette',
            resource: entityColorPalette
          },
        ]
      },
    ]
  }
]
